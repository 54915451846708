import {
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Card,
  CardContent,
  Fab,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../styles/main.css";
import {
  faListCheck,
  faEnvelope,
  faChartArea,
  faSailboat,
  faAnchor,
  faMagnifyingGlassArrowRight,
  faTruckRampBox,
  faGraduationCap
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import na5 from "../images/webp/img4.webp";

const buttonSx = {
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
  m: 2,
  textAlign: "center",
};

const buttonCompanyButtonSx = {
  color: "white",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#12a79d",
  },
  m: 2,
  textAlign: "center",
};

export default function OurServices() {
  const navigate = useNavigate();
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center", md: "center" },
            }}
          >
            Our Services.
            <Button
              variant="outlined"
              sx={buttonCompanyButtonSx}
              href={
                "https://orion-vetting.com/files/ORION_VETTING_PORTRAIT.pdf"
              }
              target="_blank"
            >
              Download Company Portrait
            </Button>
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Container  sx={{
                paddingBottom: "15px",
                width: "100%",
                borderRadius: 2,
                textAlign: "center",
                display: "flex",
                justifyContent: "center"
            }}>
            <Box
                    component="img"
                    sx={{
                      width: { xs: "90%", sm: "80%", md: "50%" },
                      mx: { xs: 1, sm: 3, md: 10 },
                      borderRadius: 2,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                    alt="PreVetting Image 5"
                    src={na5}
                  />
            </Container>
            <Grid container spacing={5} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={7}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">
                      Marine Assurance & Inspections
                    </span>
                  </span>
                </Typography>
                <Typography color="white"></Typography>
                <Box sx={{ color: "white" }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Competency Assessments / Audits (navigation, cargo &amp; mooring)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Engineering Audits (TMSA Ch. 6)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Hazard Training Programme (TMSA Ch. 9)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• SIRE 2.0 gap analysis" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Tanker pre-vetting marine inspections (SIRE &amp; CDI)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Flag state inspections (Liberian Registry)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• CPD accredited marine/class surveys" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Marine Audits for standards ISM, ISPS, MLC, ISO" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Implementation of Integrated Management Systems (ISO 9001, 14001, 45001)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Marine incident investigations" />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">Technical Services</span>
                  </span>
                </Typography>
                <Box sx={{ color: "white" }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Pre-purchase vessel inspections" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Dry docking supervision" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• New building supervision" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Retrofits & special projects" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Technical surveys (tankers & dry bulk)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Shipowner representation" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Technical support & consulting" />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={8}
              sx={{
                justifyContent: "center",
                px: { xs: 3, sm: 3, md: 11 },
                pb: 10,
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Card>
                  <CardContent 
                  sx={{
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                >                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChartArea}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      SIRE 2.0 Gap Analysis
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/gap-analysis")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 0, sm: 0, md: 3 },
                }}
              >
                <Card>
                  <CardContent 
                  sx={{
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                > 
                <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSailboat}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Dynamic Navigational Assessment
                      (Sailing Audit)
                      <br/>
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/sailing-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                md={12}
                item sx={{width: "100%", display: {xs:"block", md:"flex"}, paddingRight: 0, paddingTop: "64px", paddingLeft: "65px" }}>
                <Card sx={{width: "100%",display: {xs:"block", md:"flex"}, flexDirection: {md:"column"}}}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    display: {xs:"block:", md:"flex"},
                    alignItems: "center"
                  }}
                >
                  <CardContent 
                  sx={{
                    minWidth: {xs: "100%", md:"300px" },
                    minHeight: "250px",
                    height: "100%",
                    display: {xs:"block:", md:"flex"},
                    flexDirection: "column",
                    justifyContent: "center",
                    justifyItems: "center",
                    alignContent: "center"
                  }}
                >  <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faAnchor}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Static Navigational Assessment <br/>(Port Audit)
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ 
                        display: "flex",
                        width: {xs:"90%", md:"100%"},
                        color: "white",
                        backgroundColor: "#12a79d",
                        borderColor: "#12a79d",
                        "&:hover": {
                          bgcolor: "#00948b",
                        },
                        m: 2,
                        textAlign: "center",}}
                      size="large"
                      onClick={() => navigate("/port-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
                  <CardContent sx={{display: {xs:"block:", md:"flex"}}}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                  pr: {xs: 0, md: '30px'}
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent 
                  sx={{
                    backgroundColor: "#2f2f2f",
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                >                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlassArrowRight}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Mooring Audit
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/mooring-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                  pl: {xs: 0, md: '30px'}
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent 
                  sx={{
                    backgroundColor: "#2f2f2f",
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                >                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTruckRampBox}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Tanker Cargo Audit
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/cargo-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              </CardContent>
              </Card>
              </Grid>
              
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent 
                  sx={{
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                >
                  <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Engineering Audits
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/oprational-audits")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 0, sm: 0, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent 
                  sx={{
                    minHeight: "250px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end"
                  }}
                >                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faGraduationCap}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      On Board Training Programme
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/training-programme")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
