import {
  Typography,
  Grid,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import OA from "../images/webp/pvi2.webp";

const buttonContactUsSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Engineering Audits.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Our Real-Time Engineering Operational Audits provide a hands-on, in-depth evaluation of your vessel’s
                  engineering practices and crew competency, all aligned with OCIMF TMSA Element 4 and SIRE 2.0
                  standards. We work directly with your engineering team to ensure best practices are consistently
                  applied, enhancing safety and effective resource management on board.
                  <br/>
                  <br/>
                  Whether conducted as a stand-alone service or paired with a Real-Time Navigational Audit, our
                  approach is focused on understanding your vessel’s unique operations. We identify areas needing
                  improvement, address training gaps, and highlight best practices to strengthen your safety culture.
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  <b>What We Assess:</b>
                  <br/>
                  • <b>Engineer Qualifications &amp; Certification</b>: Reviewing your team’s experience and credentials.
                  <br />
                  • <b>Open Sea / UMS Operations</b>: Evaluating unmanned machinery protocols.
                  <br />
                  • <b>Engine Maintenance</b>: Checking the effectiveness of maintenance routines.
                  <br />
                  • <b>Pre-Arrival &amp; Pre-Departure Checks</b>: Ensuring thorough voyage prep.
                  <br />
                  • <b>Maneuvering Procedures</b>: Assessing inbound and outbound operations.
                  <br />
                  • <b>Engine Room &amp; Steering Compliance</b>: Reviewing safety protocols.
                  <br/>
                  • <b>Environmental Standards</b>: Ensuring adherence to regulations.
                  <br />
                  • <b>Emergency Preparedness</b>: Assessing readiness and response plans.
                  <br />
                  • <b>Communication</b>: Evaluating team communication effectiveness.
                  <br />
                  • <b>Training Needs</b>: Identifying areas for crew development.
                  <br />
                  • <b>Actionable Insights</b>: Offering observations and clear improvement steps.
                  <br />
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                <b>What You Receive:</b>
                <br/>
                A detailed report with best practices and highlighted areas for improvement, complete with actionable
                recommendations to boost safety and compliance. We deliver insights that empower your engineering
                team to operate more effectively and safely.
                </Typography>
                <br/>
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 2, sm: 3 },
                    width: { xs: "90%", sm: "80%", md: "40%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={OA}
                />
                <br/>
                <Typography
                  color="white"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                      <Card
                        sx={{
                          pt: 2,
                          mb: 5,
                        }}
                      >
                        <CardContent>
                          BOOK OUR SERVICES HERE!
                          <br />
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
