import {
  Typography,
  Grid,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import MA from "../images/webp/MA.jpg";

const buttonContactUsSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Mooring Audit.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  component="img"
                  sx={{
                    mb: { xs: 2, sm: 3 },
                    mx: { xs: 1, sm: 1, md: 3 },
                    width: { xs: "90%", sm: "80%", md: "40%" },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={MA}
                />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Ensuring safe and effective mooring operations is critical for any vessel. Our <b>Mooring Audit</b> provides a
                  thorough examination during a port stay, and when possible, is paired with a <b>Navigational Audit
                  (sailing)</b> to observe a live mooring maneuver.
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  This audit will:
                  <br/>
                  • <b>Verify Compliance</b>: Check adherence to legal and company-specific mooring standards.
                  <br />
                  • <b>Highlight Practices</b>: Recognize areas needing improvement and celebrate best practices.
                  <br />
                  • <b>Assess Procedures</b>: Identify gaps in current mooring procedures and recommend
                  enhancements.
                  <br />
                  • <b>Elevate Standards</b>: Drive continuous improvement in mooring and navigational practices.
                  <br />
                  • <b>Spot Knowledge Gaps</b>: Uncover areas where training is required and improve crew awareness.
                  <br />
                  • <b>Inspire Crew</b>: Engage and motivate your crew to prioritize safety and efficiency.
                  <br />
                  • <b>Provide Real-Time Training</b>: Deliver practical, hands-on instruction to address any immediate
                  needs.
                  <br />
                  <br />
                  You will receive a comprehensive assessment of your mooring equipment, its condition, and your crew&#39;s
                  competence in operating and maintaining it correctly.
                </Typography>
                <br />
                
                <Typography
                  color="white"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                      <Card
                        sx={{
                          pt: 2,
                          mb: 5,
                        }}
                      >
                        <CardContent>
                          BOOK OUR SERVICES HERE!
                          <br />
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
