import {
  Typography,
  Grid,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import SNA from "../images/webp/SNA.webp";
import SNA1 from "../images/webp/sl1.webp";

import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-solid-svg-icons";

const buttonContactUsSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const buttonSx = {
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
  m: 2,
  textAlign: "center",
};




const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function PortAudit() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Static Navigational Assessment. <br/> (Port Audit)
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  component="img"
                  sx={{
                    width: { xs: "90%", sm: "80%", md: "60%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="PreVetting Image 5"
                  src={SNA1}
                />
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                </Typography>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Our <b>Static Navigational Assessment</b> is designed to thoroughly evaluate your vessel’s compliance and
                  operational efficiency during a typical harbor stay during which our auditors work closely with your team
                  to ensure every aspect of your navigation standards meets or exceeds industry benchmarks.
                  <br/>
                  <br/>
                  We take a hands-on approach, meticulously reviewing key documents like logbooks, passage plans, and
                  maintenance records. Our auditors also engage in meaningful conversations with your officers, gaining a
                  deep understanding of your vessel’s operations and identifying areas for improvement.
                  <br/>
                  <br/>
                  If time permits, we can integrate this audit with a <b>Mooring Audit or Cargo Audit</b>, offering a
                  comprehensive assessment that addresses multiple critical areas of vessel performance and safety.
                  <br/>
                  <br/>
                  Let us help your crew achieve excellence through detailed, supportive audits that
drive continuous improvement.
                </Typography>
                <br />
                <Box
                  component="img"
                  sx={{
                    width: { xs: "90%", sm: "80%", md: "30%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="PreVetting Image 5"
                  src={SNA}
                /><br/>
                <Grid
              container
              spacing={8}
              sx={{
                justifyContent: "center",
                px: { xs: 3, sm: 3, md: 11 },
                pb: 5,
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCompass}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Mooring Audit
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/mooring-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 0, sm: 0, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Tanker Cargo Audit
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/cargo-audit")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Typography variant="h4" sx={{mt:7, pl: 7}}>OR</Typography>
            </Grid>
                <Typography
                  color="white"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                      <Card
                        sx={{
                          pt: 2,
                          mb: 5,
                        }}
                      >
                        <CardContent>
                          BOOK OUR SERVICES HERE!
                          <br />
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
