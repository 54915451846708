import * as React from "react";
import { Box, Button, Fab } from "@mui/material";
import PageTransition from "./PageTransition";
import WavyText from "./WavyText";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import shipyardMainImage from "../images/webp/shipyard-home.webp";
import shipyardMainImage2 from "../images/webp/shipyardMainImage2.jpg";
import shipyardMainImage3 from "../images/webp/shipyardMainImage3.jpg";
import shipyardMainImage4 from "../images/webp/shipyardMainImage4.jpg";
import shipyardMainImage5 from "../images/webp/shipyardMainImage5.jpg";

import ButtonAnimation from "./ButtonAnimation";
import { AnimatePresence, motion } from "framer-motion";

export default function Home() {
  const [replay, setReplay] = React.useState(true);
  const navigate = useNavigate();

  const buttonGetStartedSx = {
    my: 2,
    color: "white",
    backgroundColor: "#12a79d",
    borderColor: "#00ffef",
    "&:hover": {
      bgcolor: "#00948b",
    },
  };

  const images = [
    shipyardMainImage3,
    shipyardMainImage2,
    shipyardMainImage4,    
    shipyardMainImage5,
    shipyardMainImage,
  ];

  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  React.useEffect(() => {
    images.forEach((image) => {
      new Image().src = image;
  });

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3500); // 5 seconds interval

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [images.length]);

  return (
    <PageTransition>
      <Box
        sx={{
          maxHeight: "100dvh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "100%",
            textAlign: "center",
            display: "block",
            transform: "translateY(-50%)",
            zIndex: "2",
          }}
        >
          <Box
            sx={{
              fontSize: { xs: "3rem", sm: "4rem", md: "6rem" },
              fontWeight: "300",
              color: "#e0f1ff",
            }}
          >
            <WavyText
              text="Orion"
              replay={replay}
              delay={0.5}
              style={{ paddingRight: 20 }}
            />
            <WavyText
              text="Vetting"
              replay={replay}
              delay={0.7}
              color="#0bccc0"
            />
          </Box>
          <Box
            sx={{
              ml: { xs: 0, sm: 20, md: 30 },
              fontSize: { xs: "1rem", sm: "1rem", md: "1.7rem" },
              fontWeight: "300",
            }}
          >
            <WavyText
              text="...tailored marine solutions for your business needs"
              replay={replay}
              delay={0.7}
              color="white"
            />
          </Box>
          <Box
            sx={{
              ml: { xs: 0, sm: 20, md: 30 },
              fontSize: { xs: "1rem", sm: "1rem", md: "1.7rem" },
              fontWeight: "300",
            }}
          >
            <ButtonAnimation>
              <Button
                variant="contained"
                sx={buttonGetStartedSx}
                size="large"
                onClick={() => navigate("/about-us")}
              >
                Get Started
              </Button>
            </ButtonAnimation>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            minWidth: "100%",
            height: "100dvh",
            position: "relative",
            overflow: "hidden",
          }}
        ><AnimatePresence>
        {images.map((image, index) =>
          index === currentImageIndex ? (
            <motion.div
              key={image} // Ensures a new motion.div is rendered for animation
              initial={{ opacity: 0,  }}
              animate={{ opacity: 1}}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }} // Animation duration
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "brightness(60%)",
              }}
            />
          ) : null
        )}
      </AnimatePresence></Box>
      </Box>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
