import {
  Typography,
  Grid,
  Box,
  Fab,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import na3 from "../images/webp/na3.webp";
import DNA from "../images/webp/DNA.webp";


const buttonContactUsSx = {
  mt: 2,
  backgroundColor: "#12a79d",
  color: "white",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
            pb: 5,
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center", md: "center" },
            }}
          >
            Dynamic Navigational Assessment. <br/>(Sailing Audit)
            <Box
              component="img"
              sx={{
                display: {xs:"none", md:"inline-block"},
                position: "absolute",
                width: { xs: "64px", sm: "64px", md: "64px" },
              }}
              alt="Navigation Assessement Image 1"
              src={na3}
            />
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box
                    component="img"
                    sx={{
                      mb: { xs: 2, sm: 3 },
                      width: { xs: "90%", sm: "80%", md: "30%" },
                      mx: { xs: 1, sm: 3, md: 10 },
                      borderRadius: 2,
                    }}
                    alt="Navigation Assessement Image 2"
                    src={DNA}
                  />
                <Typography
                  color="white"
                  sx={{
                    textAlign: { xs: "justify", sm: "justify", md: "left" },
                  }}
                >
                  Our <b>Dynamic Navigational Assessment</b> involves having one of our experienced Assessors join your crew for
                  2-3 days, experiencing your vessel&#39;s operations firsthand and sharing practical, real-time feedback. We
                  don’t just observe—we become part of your team, working alongside your officers to understand the
                  unique challenges you face.
                </Typography>
                <br/>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                  }}
                >
                  Our auditors are here to help your Bridge Team by providing a personalized, engaging environment where
                  learning and improvement happen together. Here’s what we focus on during our Sailing Audit:
                </Typography>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  • <b>Understanding Your Compliance</b>: We walk through your procedures to ensure you&#39;re not just
                  compliant but confident in meeting legal and company standards.
                  <br />
                  • <b>Highlighting What Works and What Doesn’t</b>: Together, we identify areas of excellence and
                  those that need refining to strengthen overall performance.
                  <br />
                  • <b>Recommending Practical Changes</b>: Our insights aren’t just theoretical; we provide actionable
                  improvements that can be easily integrated into your daily operations.
                  <br />
                  • <b>Enhancing Navigational Skills</b>: We work with your team to promote safer, more efficient
                  navigation, tailored to your specific routes and routines.
                  <br />
                  • <b>Closing Knowledge Gaps</b>: We assess your crew&#39;s awareness and offer guidance to ensure
                  everyone is on the same page.
                  <br />
                  • <b>Motivating Your Team</b>: Through on-the-spot training, we empower your crew with the
                  confidence and skills needed for peak performance.
                  <br />
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                  }}
                >
                  Our <b>goal</b> is simple: to be a supportive part of your journey, helping your crew achieve navigational
                  excellence and safety with insights that make a difference.
                </Typography>
                <br />
                
                <Typography
                  color="white"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={8}>
                      <Card
                        sx={{
                          py: 4,
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6">
                            BOOK OUR SERVICES BY SCHEDULING A MEETING BELOW!
                          </Typography>
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
