import {
  Typography,
  Grid,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import CRT from "../images/webp/CRT.jpg";

const buttonContactUsSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            On Board Training Programme.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                  <Box
                    component="img"
                    sx={{
                      mb: { xs: 2, sm: 3 },
                      mx: { xs: 1, sm: 1, md: 3 },
                      width: { xs: "90%", sm: "80%", md: "40%" },
                      borderRadius: 2,
                    }}
                    alt="Navigation Assessement Image 2"
                    src={CRT}
                  />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                Our <b>On Board Training Programme</b> is all about empowering your crew through hands-on, practical
                training tailored to your vessel’s unique needs. Our experienced Quality Assurance trainers join your
                crew on board, delivering impactful sessions focused on proactive Risk Management, the Human
                Element, and effective hazard identification.
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Aligned with <b>TMSA standards</b>, our program ensures your team is fully prepared:
                  <br/>
                  • <b>TMSA 3.3.3</b>: We go beyond the basic STCW and regulatory requirements, equipping your crew
                  with advanced training to handle any challenge confidently.
                  <br />
                  • <b>TMSA 9A.4.2</b>: Our Experienced Safety Trainers sail alongside your team, offering continuous, on-the-job
                  guidance while reinforcing your company’s safety culture and values.
                  <br />
                  • <b>TMSA 9A.2.2A</b>: We provide comprehensive training on hazard identification and risk
                  assessment, giving your crew the skills they need to maintain a safe working environment.
                  <br />
                </Typography>
                <br />
                <Typography
                  color="white"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                      <Card
                        sx={{
                          pt: 2,
                          mb: 5,
                        }}
                      >
                        <CardContent>
                          BOOK OUR SERVICES HERE!
                          <br />
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
